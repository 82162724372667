import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, useLocation, Outlet } from "react-router-dom";

import LocalizedRoutes from "./i18n/LocalizedRoutes";
import Spinner from "@components/core/atoms/Spinner";
import PageLayout from "@pages/PageLayout";
import FixturesCalendar from "@pages/FixturesCalendar";
import SearchPage from "@pages/SearchPage";
import News from "@pages/News";
import AppRoutesKeys from "i18n/AppRoutesKeys";

import ErrorFallback from "@components/core/modules/ErrorFallback";

const CompetitionPage = lazy(() => import("@pages/CompetitionPage"));
const FixturePage = lazy(() => import("@pages/FixturePage"));
const ArticlePage = lazy(() => import("@pages/Article"));

const Fallback = () => (
  <div
    style={{
      display: "flex",
      width: "100%",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Spinner />
  </div>
);

const ScrollToTop = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <ScrollToTop />
        <LocalizedRoutes>
          <Route path="/" element={<PageLayout />}>
            <Route path=":lang" element={<Outlet />}>
              <Route path={AppRoutesKeys.news} element={<News />} />

              <Route path="article">
                <Route path=":title">
                  <Route path=":articleId" element={<ArticlePage />} />
                </Route>
              </Route>

              <Route path="" element={<FixturesCalendar />} />

              <Route
                path={AppRoutesKeys.competitions}
                element={<SearchPage />}
              />

              <Route path="*" element={<ErrorFallback />} />

              <Route path={AppRoutesKeys.sport} element={<Outlet />}>
                <Route path={AppRoutesKeys.league}>
                  <Route path=":competitionName">
                    <Route
                      path=":competitionId/:tab?/:stage?"
                      element={<CompetitionPage />}
                    />
                  </Route>
                </Route>
                <Route path={AppRoutesKeys.livegame}>
                  <Route path=":fixtureDetails">
                    <Route path=":fixtureId" element={<FixturePage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </LocalizedRoutes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
