import { Layout } from "antd";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer style={styles.container}>
      <div style={styles.content}>
        <div style={styles.copyright.container}>
          <a href="/">
            <span style={styles.copyright.title}>OnDebrief</span>
          </a>
          <span style={styles.copyright.description}>
            © Copyright {new Date().getFullYear()} OnDebrief
          </span>
        </div>
      </div>
    </Footer>
  );
};

const styles = {
  container: {
    width: "100%",
    backgroundColor: "rgba(255,255,255,1)",
    borderTop: "1px solid rgba(221,221,221,0.5)",
    padding: 0,
  },
  content: {
    margin: "auto",
    width: "100%",
    maxWidth: "1200px",
    paddingTop: 20,
    paddingBottom: 70,
  },
  copyright: {
    container: {
      paddingLeft: 20,
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontSize: "min(max(18px, 1.5vw), 23px)",
      fontFamily: "DINNextLTPro-Bold",
      color: "#6d6a7f",
    },
    description: { fontSize: "16px" },
  },
};

export default AppFooter;
