import { Button } from "antd";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const DayBox = ({
  day,
  dateFormat,
  mobileDateFormat,
  mobilView,
  click,
  selected,
}) => {
  const { t, i18n } = useTranslation();

  const generateText = (locale) => {
    moment.locale(locale);
    let name;

    if (
      moment().subtract(1, "days").locale(locale).format(dateFormat) ===
      moment(day).locale(locale).format(dateFormat)
    ) {
      name = t("matchs.yesterday");
    }

    if (
      moment().locale(locale).format(dateFormat) ===
      moment(day).locale(locale).format(dateFormat)
    ) {
      name = t("matchs.today");
    }

    if (
      moment().locale(locale).add(1, "days").format(dateFormat) ===
      moment(day).locale(locale).format(dateFormat)
    ) {
      name = t("matchs.tomorrow");
    }

    if (!name) {
      name = moment(day)
        .locale(locale)
        .format(mobilView ? mobileDateFormat : dateFormat);
    }

    return name;
  };

  const [title, setTitle] = useState(generateText(i18n.resolvedLanguage));

  useEffect(() => {
    setTitle(generateText(i18n.resolvedLanguage));
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    setTitle(generateText(i18n.resolvedLanguage));
  }, [day]);

  let className = mobilView ? "dayBoxMobile" : "dayBoxDesktop";
  if (selected) {
    className = mobilView ? `dayBoxMobile-selected` : `dayBoxDesktop-selected`;
  }

  return (
    <Button className={className} onClick={() => click(day)}>
      <span style={{ ...styles.name, fontWeight: mobilView ? "500" : "bold" }}>
        {title}
      </span>
    </Button>
  );
};

const styles = {
  name: {
    fontFamily: "DINNextLTPro-Regular",
    fontSize: "min(max(12px, 1.5vw), 12px)",
    letterSpacing: 1,
  },
};

export default DayBox;
