import LocalizedLink from "@i18n/LocalizedLink";
const NavBarItem = ({ path, matchingPath, to, icon, selectedIcon }) => {
  let iconToDisplay = icon;

  if (path === matchingPath) {
    iconToDisplay = selectedIcon || icon;
  }
  return (
    <div style={styles.container}>
      <div className="bn-tab">
        <LocalizedLink to={to}>{iconToDisplay}</LocalizedLink>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
};

export default NavBarItem;
