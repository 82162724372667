import Spinner from "../../atoms/Spinner";

const SuspenseFallback = () => (
  <div style={styles.container}>
    <Spinner />
  </div>
);

const styles = {
  container: {
    width: "100%",
    height: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f8F8F8",
  },
};

export default SuspenseFallback;
