import { Box } from "grommet";
import LocalizedLink from "@i18n/LocalizedLink";

import LazyImage from "@components/core/modules/LazyImage";
import stylesPerSize from "./stylesPerSize";
import useMediaScreen from "@utils/hooks/useMediaScreen";

const TopNewsCard = ({
  url,
  size,
  title,
  coverImage,
  description,
  context,
}) => {
  const { isSmall, height } = useMediaScreen();

  const styles = stylesPerSize[size];

  if (isSmall && height > 1000) {
    styles.coverImage.height = parseInt(styles.coverImage.height) + 100;
  }

  return (
    <LocalizedLink to="article" url={url}>
      <Box style={styles.container}>
        <div style={styles.subContainer}>
          <div style={styles.coverImage}>
            <LazyImage
              alt=""
              src={coverImage}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              logoSize={60}
              threshold={100}
            />
          </div>

          <div style={styles.aboutContainer}>
            <div style={styles.aboutSubContainer}>
              {/* <div style={styles.dot} /> */}
              <span style={styles.aboutTitle}>{context}</span>
            </div>

            <h2 style={styles.title}>{title.toUpperCase()}</h2>

            {["large", "medium"].includes(size) ? (
              <div
                style={{
                  maxWidth: "100%",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <p style={styles.description}>{description}</p>
              </div>
            ) : null}
          </div>
        </div>
      </Box>
    </LocalizedLink>
  );
};

export default TopNewsCard;
