import axios from "axios";

const API_NEWS_ENDPOINT = "https://www.ondebrief.com/api/v1/news";

const callAPI = async (params) => {
  return (await axios.get(API_NEWS_ENDPOINT, { params })).data;
};

export const fetchData = async () => {
  return await callAPI();
};

export const fetchArticle = async (articleId) => {
  return await callAPI({ id: articleId });
};
