import COLORS from "@utils/COLORS";

const ScoreTitle = ({ value }) => (
  <span
    style={{
      fontSize: "16px",
      fontFamily: "DINNextLTPro-Bold",
    }}
  >
    {value}
  </span>
);

const containerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  color: COLORS.WHITE,
  width: 50,
  paddingTop: 3,
};

const FootballFixtureScore = ({ live, goals }) => {
  const style = {
    ...containerStyle,
    backgroundColor: live ? COLORS.LIVE : COLORS.HIGHLIGHT,
  };

  return (
    <div style={style}>
      <ScoreTitle value={goals.home} />
      <span style={{ paddingInline: 5 }}>-</span>
      <ScoreTitle value={goals.away} />
    </div>
  );
};

export default FootballFixtureScore;
