import { DateTime } from "luxon";
import * as usertz from "user-timezone";
import { getUserLocales } from "get-user-locale";

const userLocales = getUserLocales();
const userTimezone = usertz.getTimeZone();

export const getCurrentUserLocale = () => userLocales[0];

/**
 * Converts an ISO date from timestamp
 *
 * NB: By default format is made based on user locale
 *
 * @param {String} strDate date to format
 * @param {String} fromTimezone Timezone of the given date
 * @param {String} format expected format
 */
export const fromTimestampToUserTimezoneFormat = (timestamp, format) =>
  DateTime.fromSeconds(timestamp)
    .setLocale(getCurrentUserLocale())
    .toFormat(format);

/**
 * Converts an ISO date from a timezone to user timezone
 *
 * NB: By default format is made based on user locale
 *
 * @param {String} strDate date to format
 * @param {String} fromTimezone Timezone of the given date
 * @param {String} format expected format
 */
export const dateToUserTimezoneFormat = (strDate, format) =>
  dateToUserTimezone(strDate)
    .setZone(userTimezone)
    .setLocale(getCurrentUserLocale())
    .toFormat(format);

/**
 * Converts an ISO date in a timezone to user timezone
 *
 * @param {STring} strDate
 * @param {String} fromTimezone
 * @returns {DateTime}
 */
export const dateToUserTimezone = (strDate) =>
  DateTime.fromISO(strDate).setZone(userTimezone);

/**
 * Converts an ISO date to locale string
 *
 * @param {Sting} strDate
 * @returns {String}
 */
export const convertDateToLocale = (strDate, params, locale) => {
  let dateToLocalized = DateTime.fromISO(strDate);

  if (locale) {
    dateToLocalized = dateToLocalized.setLocale(locale);
  }

  return dateToLocalized.toLocaleString(params);
};

export const convertStringDateToTimestamp = (strDate) =>
  DateTime.fromISO(strDate).toMillis();

export const convertDateFromFormatToFormat = (
  strDate,
  inputFormat,
  outputFormat,
  locale
) => {
  return DateTime.fromFormat(strDate, inputFormat)
    .setLocale(locale || getCurrentUserLocale())
    .setZone(userTimezone)
    .toFormat(outputFormat);
};
