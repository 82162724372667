import LocalizedLink from "i18n/LocalizedLink";
import CompetitionMenuItem from "./CompetitionMenuItem";
import useCompetitions from "@hooks/useCompetitions";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: "#fff",
    paddingBottom: 15,
    border: "1px solid #e9e9e9",
  },
  titleContainer: {
    paddingLeft: 25,
    paddingTop: 15,
    paddingBottom: 15,
  },
  title: {
    color: "#000",
    fontSize: "16px",
    fontFamily: "DINNextLTPro-Bold",
  },
};

const CompetitionMenu = ({ theme }) => {
  const { isLoadingCompetitions, activeCompetitionId, competitions } =
    useCompetitions();

  return (
    <>
      {!isLoadingCompetitions ? (
        <div style={styles.container}>
          <div class="default" style={styles.titleContainer}>
            <span style={styles.title}>Competitions</span>
          </div>
          {competitions.map(({ id, name, logo, country }) => (
            <LocalizedLink
              to="competition-fixtures"
              params={{
                id,
                name,
              }}
            >
              <CompetitionMenuItem
                isActive={activeCompetitionId === id}
                name={name}
                id={id}
                logo={logo}
                theme={theme}
              />
            </LocalizedLink>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default CompetitionMenu;
