import { Button } from "antd";
import { Link } from "react-router-dom";

import RefereeCardIcon from "./referee-card.png";

const styles = {
  container: {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  icon: { width: 100, height: 100 },
  title: {
    fontSize: 15,
    marginTop: 30,
    marginBottom: 30,
    fontWeight: "bold",
  },
};

function ErrorFallback({ title }) {
  return (
    <div style={styles.container}>
      <img alt="" src={RefereeCardIcon} style={styles.icon} />

      <span style={styles.title}>
        {title || "Nous ne trouvons pas ce que vous cherchez."}
      </span>

      <p>Consulter la page d'accueil pour rester informé des actualités</p>

      <Link to="/">
        <Button style={{ marginTop: 20 }} size="large">
          Allez à la page d'accueil
        </Button>
      </Link>
    </div>
  );
}

export default ErrorFallback;
