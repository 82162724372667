import { useEffect, useState, useRef } from "react";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import "./styles.css";
import DayBox from "./DayBox";
import CalendarButton from "./CalendarButton";

const DayPickerCalendar = (props) => {
  const { i18n } = useTranslation();
  const [selectedDays, setSelectedDays] = useState([]);
  const [startDay, setStartDay] = useState(null);
  const [format] = useState(props.format || null);
  const [weekdays, setWeekdays] = useState([]);
  const [days, setDays] = useState(null);
  const dateContainerRef = useRef();

  const convertSelectedDays = (inputSelectedDays) => {
    const currentSelectedDays = inputSelectedDays || [moment()];

    return currentSelectedDays.map((day) => {
      if (moment.isMoment(day)) {
        return day;
      } else {
        return moment(day);
      }
    });
  };

  const convertStartDay = (inputStarDay) => {
    let convertedStartDay = moment();

    if (inputStarDay) {
      if (moment.isMoment(inputStarDay)) {
        convertedStartDay = inputStarDay;
      } else {
        convertedStartDay = moment(inputStarDay);
      }
    }

    return convertedStartDay;
  };

  const generateWeekDaysByDeviceType = (startingDay, daysCount) => {
    if (props.mobilView) {
      return generateWeekDays(
        startingDay.clone().add(-props.daysCount, "days"),
        daysCount * 2
      );
    } else {
      return generateWeekDays(startDay, daysCount);
    }
  };

  const generateWeekDays = (startingDay, daysCount) => {
    const generatedWeekdays = [];
    for (let index = 0; generatedWeekdays.length < daysCount; index++) {
      let day = startingDay.clone().add(index, "days");
      generatedWeekdays.push(day);
    }

    return generatedWeekdays;
  };

  const generateDayBoxes = (selectedWeekDays, selectedDays) => {
    let days = null;

    if (props.mobilView) {
      days = renderMobilView(selectedWeekDays, selectedDays);
    } else {
      days = renderDesktopView(selectedWeekDays, selectedDays);
    }

    return days;
  };

  const convertOutput = (dayArray) => {
    let output = dayArray.map((d) => d.clone().format(format));
    if (props.selectDay) {
      props.selectDay(output);
    }
  };

  const convertOutputUnselect = (day) => {
    let output = day.clone().format(format);
    if (props.unselectDay) {
      props.unselectDay(output);
    }
  };

  const daySelect = (day) => {
    if (day.isSame(selectedDays[0], "day")) {
      //remove existed day
      convertOutputUnselect(day);
      if (props.unselectable) {
        setSelectedDays([]);
        convertOutput([]);
      }
    } else {
      setSelectedDays([day]);
      convertOutput([day]);
    }
  };

  const checkSelectedDay = (day, selected_days) => {
    let selected = false;

    if (selected_days.length > 0) {
      selected_days.forEach((d) => {
        if (day.isSame(d, "day")) {
          selected = true;
        }
      });
    }

    return selected;
  };

  const prevWeek = () => {
    const newStartDay = startDay.clone().add(-props.daysCount, "days");
    setStartDay(newStartDay);

    if (props.onPrevClick) {
      props.onPrevClick(newStartDay, selectedDays);
    }

    if (props.mobilView) {
      daySelect(newStartDay);
    }
  };

  const nextWeek = () => {
    const newStartDay = startDay.clone().add(props.daysCount, "days");
    setStartDay(newStartDay);

    if (props.onNextClick) {
      props.onNextClick(newStartDay, selectedDays);
    }

    if (props.mobilView) {
      daySelect(newStartDay);
    }
  };

  const renderDesktopView = (weekdays, selected_days) => {
    const dayComps = weekdays.map((day, i) => (
      <DayBox
        {...props}
        day={day}
        key={i}
        click={daySelect}
        selected={checkSelectedDay(day, selected_days)}
      />
    ));
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>{dayComps}</div>
    );
  };

  const renderMobilView = (weekdays, selected_days) => {
    const dayComps = weekdays.map((day, i) => {
      const selected = checkSelectedDay(day, selected_days);
      return (
        <DayBox
          {...props}
          day={day}
          key={day.format("YYYY-MM-DD")}
          click={daySelect}
          selected={selected}
          mobilView={true}
          locale={i18n.resolvedLanguage}
        />
      );
    });
    return (
      <div
        style={{
          display: "inline-block",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {props.mobileRightExtra ? (
            <CalendarButton onClick={props.openCalendar} />
          ) : null}
          {dayComps}
          {props.mobileRightExtra ? (
            <CalendarButton onClick={props.openCalendar} />
          ) : null}
        </div>
      </div>
    );
  };

  const handleStartDayUpdate = (newStartingDay) => {
    let computedWeekdays = generateWeekDaysByDeviceType(
      newStartingDay,
      props.daysCount
    );

    const newSelectedDays = convertSelectedDays(props.selectedDays);

    const days = generateDayBoxes(computedWeekdays, newSelectedDays);

    setDays(days);
    setWeekdays(computedWeekdays);
    setSelectedDays(newSelectedDays);
  };

  useEffect(() => {
    moment().locale(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    setStartDay(convertStartDay(props.startDay));
  }, [props.startDay]);

  useEffect(() => {
    if (startDay) {
      handleStartDayUpdate(startDay);
    }
  }, [startDay]);

  useEffect(() => {
    if (startDay) handleStartDayUpdate(startDay);
  }, [props.mobilView]);

  useEffect(() => {
    if (selectedDays.length) {
      if (props.mobilView) {
        handleStartDayUpdate(convertStartDay(props.selectedDays[0]));
      } else {
        handleStartDayUpdate(startDay);
      }
    }
  }, [props.selectedDays]);

  useEffect(() => {
    const element = document.querySelector(".dayBoxMobile-selected");
    if (element && props.mobilView) {
      dateContainerRef.current.scroll({
        left: element.offsetLeft - 120, // always display previous day based on selected day - element width is 100px
        behavior: "smooth",
      });
    }
  }, [weekdays]);

  return (
    <>
      {!props.mobilView ? (
        <div style={styles.monthContainer}>
          <CalendarButton onClick={props.openCalendar} />
        </div>
      ) : null}
      <div
        style={
          props.mobilView ? styles.mobileDateContainer : styles.dateContainer
        }
      >
        {!props.mobilView ? (
          <div className="iconContainer">
            <LeftOutlined onClick={prevWeek} className="icon" />
          </div>
        ) : null}

        <div ref={dateContainerRef} className="dayBoxMobileContainer">
          {days}
        </div>

        {!props.mobilView ? (
          <div className="iconContainer">
            <RightOutlined onClick={nextWeek} className="icon" />
          </div>
        ) : null}
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: 15,
    background: "#fff",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  mobileDateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    background: "#fff",
  },
  monthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    background: "#f5f5f5",
    borderBottom: "1px solid #f5f5f5",
    backgroundColor: "#fff",
  },
  title: {
    fontWeight: "bold",
    fontSize: 15,
    color: "#6d6a7f",
    letterSpacing: 0.5,
  },
};

export default DayPickerCalendar;
