import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FootballAPI from "@api/football.api";

function useCompetitions() {
  const params = useParams();

  const [competitions, setCompetitions] = useState(null);
  const [isLoadingCompetitions, setIsLoadingCompetitions] = useState(true);
  const [activeCompetitionId, setActiveCompetitionId] = useState(null);

  const onCompetitionSelect = (competition) => {
    const { id } = competition;
    setActiveCompetitionId(id);
  };

  useEffect(() => {
    const fetchCompetitions = async () => {
      const result = await FootballAPI.getCompetitions(params.lang);

      let defaultActiveCompetition = result.find(
        (item) => item.id === params.competitionId
      );

      if (!defaultActiveCompetition) {
        defaultActiveCompetition = result[0];
      }

      setCompetitions(result);
      setActiveCompetitionId(defaultActiveCompetition.id);
      setIsLoadingCompetitions(false);
    };

    fetchCompetitions();
  }, [params.competitionId]);

  return {
    isLoadingCompetitions,
    competitions,
    activeCompetitionId,
    onCompetitionSelect,
  };
}

export default useCompetitions;
