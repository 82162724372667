import FixtureTeamLogo from "./FixtureTeamLogo";
import FixtureStatus from "./FixtureStatus";
import FixtureTeamName from "./FixtureTeamName";
import FixtureLiveStatus from "./FixtureLiveStatus";
import DataBroadcaster from "./DataBroadcaster";

import FIXTURE_STATUS from "./FixtureStatus/FIXTURE_STATUS";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    minHeight: 40,
  },
  fixtureContainer: {
    textDecoration: "none",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Fixture = ({ fixture, teams, goals, penalty }) => {
  return (
    <div style={styles.container}>
      <div style={styles.fixtureContainer}>
        <FixtureTeamName
          atHome={true}
          name={teams.home.name}
          code={teams.home.code}
        />
        <FixtureTeamLogo logo={teams.home.logo} />
        <FixtureStatus
          status={fixture.status}
          date={fixture.date}
          goals={goals}
        />
        <FixtureTeamLogo logo={teams.away.logo} />
        <FixtureTeamName name={teams.away.name} code={teams.away.code} />
      </div>
      <FixtureLiveStatus status={fixture.status} penalty={penalty} />

      {FIXTURE_STATUS.UP_COMING === fixture.status.short &&
      DataBroadcaster[fixture.id] ? (
        <span
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            fontSize: 11,
            color: "gray",
            marginTop: 8,
            fontWeight: "bold",
            lineHeight: "5pt",
          }}
        >
          {DataBroadcaster[fixture.id]}
        </span>
      ) : null}
    </div>
  );
};

export default Fixture;
