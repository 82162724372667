import COLORS from "@utils/COLORS";

const defaultStyle = {
  fontFamily: "DINNextLTPro-Regular",
  fontSize: 11,
};

const styles = {
  container: {
    display: "flex",
    backgroundColor: "transparent",
  },
  title: {
    live: {
      ...defaultStyle,
      color: COLORS.LIVE,
    },
    ended: {
      ...defaultStyle,
      color: COLORS.BLACK,
    },
  },
};

export default styles;
