import { Layout } from "antd";
import { lazy, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import BottomNavBar from "./BottomNavBar";
import useMediaScreen from "@utils/hooks/useMediaScreen";

const AppContent = lazy(() => import("./AppContent"));

const startsWithLocale = (url) => {
  // Regular expression to match locale pattern (e.g., '/en', '/fr', '/de', etc.)
  var localeRegex = /^\/[a-zA-Z]{2}(\/|$)/;

  // Check if the URL starts with a locale
  return localeRegex.test(url);
};

const AppLayout = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLarge } = useMediaScreen();

  useEffect(() => {
    const curPath = location.pathname;

    if (
      startsWithLocale(curPath) &&
      i18n.options.supportedLngs.includes(lang)
    ) {
      i18n.changeLanguage(lang);
    } else if (
      startsWithLocale(curPath) &&
      !i18n.options.supportedLngs.includes(lang)
    ) {
      //user requested an unsupported language
      // replace it by default language
      navigate(curPath.replace(/^\/\w+/, "/" + i18n.options.fallbackLng), {
        replace: true,
      });
    } else {
      // no language specified
      navigate(`/${i18n.options.lng}${curPath}`, {
        replace: true,
      });
    }
  }, []);

  return (
    <Layout style={styles.layout}>
      <AppHeader />
      <AppContent />
      <AppFooter />
      {!isLarge ? <BottomNavBar /> : null}
    </Layout>
  );
};

const styles = {
  layout: {
    width: "100%",
  },
};

export default AppLayout;
