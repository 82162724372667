const AppRoutesKeys = {
  sport: "routes.sport",
  league: "routes.league",
  article: "routes.article",
  fixtures: "routes.fixtures",
  standings: "routes.standings",
  news: "routes.news",
  livegame: "routes.livegame",
  competitions: "routes.competitions",
};

export default AppRoutesKeys;
