import React from "react";
import { Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LocalizedRoutes = ({ children }) => {
  const { t } = useTranslation();

  // Function to check if a React component has children
  function hasChildren(component) {
    // Check if the component has props and props.children is not null or undefined
    return (
      component.props &&
      component.props.children !== null &&
      component.props.children !== undefined
    );
  }

  const transformChild = (parentChild) => {
    let res;
    if (!hasChildren(parentChild)) {
      res = React.cloneElement(parentChild, {
        ...parentChild.props,
        path: localizeRoutePath(parentChild.props.path),
      });
    } else {
      let transformedChildren;

      if (Array.isArray(parentChild.props.children)) {
        transformedChildren = [];
        React.Children.map(parentChild.props.children, (child) =>
          transformedChildren.push(transformChild(child))
        );
      } else if (typeof parentChild.props.children) {
        transformedChildren = transformChild(parentChild.props.children);
      }

      res = React.cloneElement(parentChild, {
        ...parentChild.props,
        children: transformedChildren,
        path: localizeRoutePath(parentChild.props.path),
      });
    }

    return res;
  };

  /**
   *
   * @param path can be string, undefined or string array
   * @returns Localized string path or path array
   */
  function localizeRoutePath(path) {
    return path.startsWith(":") || path === "/" || path === "" ? path : t(path);
  }

  return (
    <Routes>
      {React.Children.map(children, (child) => transformChild(child))})
    </Routes>
  );
};

export default LocalizedRoutes;
