import PropTypes from "prop-types";
import FixtureScore from "./FixtureScore";
import Utils from "@utils";

import FIXTURE_STATUS from "./FIXTURE_STATUS";
import FIXTURE_STATUS_TITLE from "./FIXTURE_STATUS_TITLE";

const { DateUtils } = Utils;

const styles = {
  text: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 50,
    maxWidth: 60,
    minHeight: 30,
  },
};
const Text = ({ value, style }) => (
  <div style={styles.text}>
    <span style={style ? style : { fontSize: 12 }}>{value}</span>
  </div>
);

const FixtureStatus = ({ status, date, goals }) => {
  let title;
  let style;

  const { live, short } = status;

  if (FIXTURE_STATUS.UP_COMING === short) {
    style = { fontSize: 13 };
    title = DateUtils.dateToUserTimezoneFormat(date, "HH':'mm");
  } else if (
    ![FIXTURE_STATUS.ENDED, FIXTURE_STATUS.UP_COMING].includes(short)
  ) {
    title = FIXTURE_STATUS_TITLE[short];
    style = { color: "#91145b", fontSize: 12 };
  }

  return (
    <div style={{ paddingInline: 5 }}>
      {title ? <Text style={style} value={title} /> : null}

      {goals.home !== null ? <FixtureScore live={live} goals={goals} /> : null}
    </div>
  );
};

FixtureStatus.propTypes = {
  score: PropTypes.shape({
    goals: PropTypes.shape({
      home: PropTypes.number.isRequired,
      away: PropTypes.number.isRequired,
    }),
    penalty: PropTypes.shape({
      home: PropTypes.number.isRequired,
      away: PropTypes.number.isRequired,
    }),
  }),
};

export default FixtureStatus;
