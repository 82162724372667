import styles from "./styles";

const FootballFixtureScoreDetails = ({ isLive, title }) => {
  return (
    <div style={styles.container}>
      <span style={isLive ? styles.title.live : styles.title.ended}>
        {title}
      </span>
    </div>
  );
};

export default FootballFixtureScoreDetails;
