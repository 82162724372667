/**
 * Writes string in PascalCase format
 *
 * @param {String}
 * @returns {String}
 */
export const toPascalCase = (str) => {
  return str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const transformTeamName = (teamName) =>
  teamName
    .trim()
    .toLowerCase()
    .replaceAll(" ", "-")
    .replace("'", "")
    .replace("/", "-")
    .replace(".", "-");

export const generateFixtureURL = (fixtureId, homeTeamName, awayTeamName) => {
  return `/football/match-en-direct/${transformTeamName(
    homeTeamName
  )}-${transformTeamName(awayTeamName)}/${fixtureId}`;
};
