const styles = {
  container: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    paddingTop: 2,
    paddingBottom: 2,
  },
  title: {
    color: "#1d1d1d",
    paddingLeft: 14,
    fontSize: 12,
    letterSpacing: 0.4,
    fontWeight: "bold",
  },
};

export default styles;
