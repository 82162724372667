const FixtureBroadcaster = {
  1035432: "Canal+ Foot", //  west-ham-vs-brentford, Lun 26 Fev
  1052505: "Bein Sports 2", // fiorentina vs lazio,  Lun 26 Fev
  1052510: "Bein Sports 2", // as-roma-vs-torino, Lun 26 Fev,
  1038205: "Bein Sports 6", //girona-vs-rayo-vallecano Lun 26 Fev,

  1172708: "Bein Sports 1", //lyon-vs-strasbourg, Mar 27 Fev
  1168796: "la chaine L'Équipe", //real-sociedad-vs-mallorca, Mar 27 Fev

  1172709: "Bein Sports 1", //rouen-vs-valenciennes, Mer 28 Fev
  1052460: "Bein Sports 1", //sassuolo-vs-napoli/, Mer 28 Fev
  1052456: "Bein Sports 5", //inter-vs-atalanta, Mer 28 Fev

  1172710: "Bein Sports 1", //le-puy-foot-vs-rennes, Jeu 29 Fev
  1168797: "L'Équipe", //athletic-club-vs-atletico-madrid, Jeu 29 Fev

  1045090: "Prime Vidéo", //monaco-vs-paris-saint-germain, Ven 01 Mar
  1049089: "Bein Sports 1", //sc-freiburg-vs-bayern-munich, Ven 01 Mar
  1052518: "Bein Sports 5", //lazio-vs-ac-milan, Ven 01 Mar
  1038215: "Bein Sports 6", //celta-vigo-vs-almeria, Ven 01 Mar

  1045093: "Prime Vidéo", // reims-vs-lille
  1045092: "Canal+ Foot, Canal+ Sport 360, DAZN", //clermont-foot-vs-marseille
  1035441: "Canal+ Foot", // nottingham-forest-vs-liverpool
  1035438: "Canal+ Foot", // luton-vs-aston-villa
  1038219: "Bein Sports 3", //sevilla-vs-real-sociedad
  1038216: "Bein Sports 8", //rayo-vallecano-vs-cadiz
  1038214: "Bein Sports 5", //getafe-vs-las-palmas
  1038218: "Bein Sports 1",

  1052521: "Bein Sports 2", //torino-vs-fiorentina
  1052519: "Bein Sports 1", //monza-vs-as-roma
  1052522: "Bein Sports 4", //udinese-vs-salernitana

  1049090: "Bein Sports 4", //vfl-wolfsburg-vs-vfb-stuttgart
  1049088: "Bein Sports 2", //union-berlin-vs-borussia-dortmund
  1049091: "Bein Sports 7", //fsv-mainz-05-vs-borussia-monchengladbach/
  1049094: "Bein Sports 5", //vfl-bochum-vs-rb-leipzig/

  1049095: "Bein Sports 6", // fc-heidenheim-vs-eintracht-frankfurt/
};

export default FixtureBroadcaster;
