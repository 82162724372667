import styles from "./styles";

const CompetitionFixturesHeader = ({ league }) => {
  const { logo, name } = league;

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.logoContainer}>
          <img src={logo} alt={`${name}-logo`} style={styles.logo} />
        </div>

        <div style={styles.competitionsDetails}>
          <span style={styles.competitionName}>{name}</span>
        </div>
      </div>
    </div>
  );
};

export default CompetitionFixturesHeader;
