import * as DateUtils from "./date.utils";
import * as StringUtils from "./string.utils";

const CommonUtils = {
  getRGB: (hexColor) => {
    const color = parseInt(hexColor.substring(1), 16);
    const r = (color >> 16) & 255;
    const g = (color >> 8) & 255;
    const b = color & 255;

    return [r, g, b];
  },

  isTwoHexColorsSimilar: (firstHex, secondHex) => {
    const [r1, g1, b1] = CommonUtils.getRGB(firstHex);
    const [r2, g2, b2] = CommonUtils.getRGB(secondHex);

    const threshold = 30; // Adjust this threshold to your preference

    return (
      Math.abs(r1 - r2) <= threshold &&
      Math.abs(g1 - g2) <= threshold &&
      Math.abs(b1 - b2) <= threshold
    );
  },
};

const Utils = {
  DateUtils,
  StringUtils,
  CommonUtils,
};

export default Utils;
