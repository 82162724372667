const defaultStyles = {
  container: {
    display: "inline-block",
    paddingTop: "10px",
    paddingBottom: "10px",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "15px",
    overflow: "hidden",
    textAlign: "left",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#000",
  },
  flag: {
    width: 17,
    height: 17,
    marginRight: 5,
    objectFit: "contain",
  },
  competitionName: {
    color: "#000",
    fontSize: "13px",
    fontFamily: "DINNextLTPro-Regular",
  },
};

const styles = {
  dark: {
    activeAndHovering: {
      ...defaultStyles.container,
      backgroundColor: "#f5f5fa",
    },
    notActiveAndHovering: {
      ...defaultStyles.container,
      backgroundColor: "#f5f5fa",
    },
    activeAndNotHovering: {
      ...defaultStyles.container,
    },
    notActiveAndNotHovering: {
      ...defaultStyles.container,
    },
  },
  ...defaultStyles,
};

export default styles;
