const COLORS = {
  GRAY: "gray",
  LIGHT_GRAY: "#f8f8f8",
  GREEN: "#2fbf61",
  ROSE: "#ffad99",
  RED: "#ff471a",
  BLACK: "#000",
  LIGHT_BLACK: "#3E4141",
  WHITE: "#fff",
  HIGHLIGHT_NEW: "#020040",
  HIGHLIGHT: "#4D0754",
  NEW_ROSE: "#FFF",
  NEW_ROSE_NEW: "#fde7cf",
  LIVE: "#34a853",
};

export default COLORS;
