const FIXTURE_STATUS = {
  UP_COMING: "upcoming",
  ENDED: "ended",
  POSTPONED: "postponed",
  STOPPED: "stopped",
  CANCELED: "canceled",
  SUSPENDED: "suspended",
  INTERRUPTED: "interrupted",
  ABANDONED: "abandoned",
  TO_BE_DEFINED: "to_be_defined",
};

export default FIXTURE_STATUS;
