import { render } from "react-dom";
import "./i18n/i18nconfig.js";
import "antd/dist/antd.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
