const styles = {
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    paddingInline: "15px",
    backgroundColor: "#f5f5f5",
    // borderTopRightRadius: 10,
    // borderTopLeftRadius: 10,
    height: 40,
    // border: "1px solid #eff0f2",
    borderBottom: "none",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    margin: "auto",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "contain",
  },
  logo: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  competitionRound: {
    fontFamily: "DINNextLTPro-Regular",
    fontSize: "min(max(13px, 1.4vw), 14px)",
    letterSpacing: 0,
    color: "#7f7b8e",
    lineHeight: "12pt",
  },
  competitionName: {
    color: "#1a1a2a",
    fontFamily: "DINNextLTPro-Bold",
  },
  competitionsDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
};

export default styles;
