import { useState } from "react";
import { Drawer, Button } from "antd";
import moment from "moment";
import { LocalizationProvider, CalendarPicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function DrawerCalendarDatePicker({ onSelect, onClose, visible, placement }) {
  const [value, setValue] = useState(moment());

  return (
    <Drawer
      placement={placement}
      closable={true}
      onClose={onClose}
      open={visible}
      contentWrapperStyle={{
        height: 480,
      }}
    >
      <div>
        <div
          style={{
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CalendarPicker
              openTo="day"
              view="day"
              views={["day"]}
              date={value}
              onChange={(newDate) => setValue(newDate)}
            />
          </LocalizationProvider>
        </div>
        <div
          style={{
            float: "right",
            padding: 15,
          }}
        >
          <Button
            style={{ marginRight: 10, backgroundColor: "#f5f5fa" }}
            onClick={onClose}
          >
            <span style={styles.buttonTitle}>CANCEL</span>
          </Button>
          <Button
            style={{ backgroundColor: "#000", borderColor: "#000" }}
            type="primary"
            onClick={() => {
              onSelect(value);
              onClose();
            }}
          >
            <span style={styles.buttonTitle}>APPLY</span>
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const styles = {
  buttonTitle: { letterSpacing: 0.5 },
};

export default DrawerCalendarDatePicker;
