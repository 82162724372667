import { LazyLoadComponent } from "react-lazy-load-image-component";

import LocalizedLink from "@i18n/LocalizedLink";
import CompetitionFixturesHeader from "./CompetitionFixturesHeader";
import FixturesTable from "@components/competitions/FixturesTable";

function CompetitionFixturesTable({ fixtures }) {
  const league = fixtures[0].league;

  return (
    <LazyLoadComponent>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          backgroundColor: "#fff",
          borderRadius: 10,
          marginBottom: 20,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          border: "1px solid #e9e9e9",
        }}
      >
        <FixturesTable
          key={league.id}
          title={
            <LocalizedLink
              to="competition-fixtures"
              params={{ id: league.id, name: league.name }}
            >
              <CompetitionFixturesHeader league={league} />
            </LocalizedLink>
          }
          fixtureKey={(item) => item.fixture.id}
          fixtures={fixtures}
        />
      </div>
    </LazyLoadComponent>
  );
}

export default CompetitionFixturesTable;
