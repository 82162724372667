import { LazyLoadImage } from "react-lazy-load-image-component";

const logoStyle = {
  xs: {
    width: 18,
    height: 18,
    borderRadius: 9,
    objectFit: "fill",
  },
  xmd: {
    width: 20,
    height: 20,
    borderRadius: 10,
    objectFit: "fill",
  },
  md: {
    width: 22,
    height: 22,
    borderRadius: 11,
    objectFit: "fill",
  },
  lg: {
    width: 30,
    height: 30,
    borderRadius: 15,
    objectFit: "fill",
  },
};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const TeamLogo = ({ name, size }) => {
  return (
    <div style={containerStyle}>
      <LazyLoadImage
        src={name}
        style={logoStyle[size] || logoStyle.xs}
        alt=""
      />
    </div>
  );
};

export default TeamLogo;
