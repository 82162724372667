import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AppRoutesKeys = {
  sport: "routes.sport",
  league: "routes.league",
  article: "routes.article",
  fixtures: "routes.fixtures",
  standings: "routes.standings",
  news: "routes.news",
  livegame: "routes.livegame",
  search: "routes.search",
};

const linkStyle = { textDecoration: "none", color: "#000" };

const LocalizedLink = ({ children, to, params, style, ...others }) => {
  const { t, i18n } = useTranslation();
  let basePath = `/${i18n.resolvedLanguage}`;
  let route;

  const transformTeamName = (teamName) =>
    teamName
      .trim()
      .toLowerCase()
      .replace("'", "")
      .replace("' ", "")
      .replaceAll(" ", "-")
      .replace("/", "-")
      .replace(".", "-");

  switch (to) {
    case "home":
      route = basePath + "/";
      break;

    case "news":
      route = `${basePath}/${t(AppRoutesKeys.news)}`;
      break;

    case "article":
      route = `${basePath}${others.url}`;
      break;

    case "competitions":
      route = `${basePath}/competitions`;
      break;

    case "competition":
      route = `${basePath}/${t(AppRoutesKeys.sport)}/${t(
        AppRoutesKeys.league
      )}/${transformTeamName(params.name)}/${params.id}}`;
      break;

    case "competition-standings":
      route = `${basePath}/${t(AppRoutesKeys.sport)}/${t(
        AppRoutesKeys.league
      )}/${transformTeamName(params.name)}/${params.id}/${t(
        AppRoutesKeys.standings
      )}`;
      break;

    case "competition-fixtures":
      route = `${basePath}/${t(AppRoutesKeys.sport)}/${t(
        AppRoutesKeys.league
      )}/${transformTeamName(params.name)}/${params.id}/${t(
        AppRoutesKeys.fixtures
      )}${params.round ? `/${params.round.trim()}` : ""}`;
      break;

    case "fixture":
      route = `${basePath}/${t(AppRoutesKeys.sport)}/${t(
        AppRoutesKeys.livegame
      )}/${transformTeamName(params.homeTeamName)}-vs-${transformTeamName(
        params.awayTeamName
      )}/${params.id}`;
      break;

    default:
      break;
  }

  return (
    <Link {...others} to={route} style={style || linkStyle}>
      {children}
    </Link>
  );
};

export default LocalizedLink;
