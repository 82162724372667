const homeStyle = {
  marginLeft: 0,
  marginRight: 5,
  textAlign: "right",
};

const awayStyle = {
  marginLeft: 5,
  marginRight: 0,
  textAlign: "left",
};

const FixtureTeamName = ({ name, atHome, highlight }) => {
  const nameStyle = {
    fontSize: "13px",
    fontWeight: highlight ? "bold" : "normal",
  };

  const containerStyle = atHome ? homeStyle : awayStyle;

  return (
    <div
      style={{
        width: "100%",
        lineHeight: "11.5pt",
        ...containerStyle,
      }}
    >
      <span style={nameStyle}>{name}</span>
    </div>
  );
};

export default FixtureTeamName;
