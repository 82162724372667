import FixtureScoreDetails from "./FixtureScoreDetails";

function FixtureLiveStatus({ status, penalty }) {
  let title;
  let fontSize;

  if (status.live && status.short === "BT") {
    title = "prolongation";
  } else if (penalty?.home) {
    title = `Pen. (${penalty.home}-${penalty.away})`;
  } else if (status.live && ["HT", "BT"].includes(status.short)) {
    title = "Mi-temps";
  } else if (status.live && ["1H", "2H", "ET"].includes(status.short)) {
    title = `${status.elapsed}'`;
    fontSize = 15;
  } else {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        maxHeight: 15,
      }}
    >
      <FixtureScoreDetails
        isLive={status.live}
        title={title}
        fontSize={fontSize}
      />
    </div>
  );
}

export default FixtureLiveStatus;
