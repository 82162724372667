import { Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const CalendarButton = ({ color, onClick }) => {
  const { t } = useTranslation();
  return (
    <Button
      icon={<CalendarOutlined style={{ color, fontSize: 18 }} />}
      type="text"
      onClick={onClick}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 35,
        backgroundColor: "transparent",
      }}
    >
      <span style={{ color, fontFamily: "DINNextLTPro-Bold" }}>
        {t("matchs.calendar")}
      </span>
    </Button>
  );
};

export default CalendarButton;
