import useMediaScreen from "@utils/hooks/useMediaScreen";
import SmallHeader from "./SmallHeader";
import LargeHeader from "./LargeHeader";

const AppHeader = () => {
  const { isLarge } = useMediaScreen();

  if (!isLarge) return <SmallHeader />;

  return <LargeHeader />;
};

export default AppHeader;
