import Fixture from "@components/competitions/Fixture";
import LocalizedLink from "@i18n/LocalizedLink";

const FixtureTeamsColumn = {
  title: "",
  dataIndex: "home_team_short_name",
  key: "home_team_short_name",
  align: "right",
  render: (_text, record) => {
    return (
      <LocalizedLink
        to="fixture"
        key={record.fixture.id}
        params={{
          id: record.fixture.id,
          homeTeamName: record.teams.home.name,
          awayTeamName: record.teams.away.name,
        }}
      >
        <Fixture
          fixture={record.fixture}
          teams={record.teams}
          goals={record.goals}
          penalty={record.score.penalty}
        />
      </LocalizedLink>
    );
  },
};

export default FixtureTeamsColumn;
