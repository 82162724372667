import DayPickerCalendar from "./DayPickerCalendar";
import useMediaScreen from "@utils/hooks/useMediaScreen";

function Calendar(props) {
  const { isLarge } = useMediaScreen();

  return (
    <div style={styles.content}>
      <DayPickerCalendar
        {...props}
        daysCount={4} //How many days will be shown
        format={"YYYY-MM-DD"} //format of dates that handled in selectDay and unselectDay functions
        dateFormat={"ddd D"}
        mobileDateFormat={"ddd D MMM"}
        mobilView={!isLarge} // enables mobil view
      />
    </div>
  );
}

const styles = {
  content: {
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default Calendar;
