const FIXTURE_STATUS_TITLE = {
  stopped: "Arreté",
  canceled: "Annulé",
  postponed: "Reporté",
  interrupted: "Interrompu",
  abandoned: "Abandonné",
  suspended: "Suspendu",
  to_be_defined: "A définir",
};

export default FIXTURE_STATUS_TITLE;
