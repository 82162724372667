import { PictureOutlined } from "@ant-design/icons";

const ImagePLaceholder = ({ logoSize }) => (
  <div
    style={{
      display: "flex",
      flexGrow: 1,
      background: "#e5e5e5",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100%",
    }}
  >
    <PictureOutlined style={{ fontSize: logoSize, color: "rgba(0,0,0,0.1)" }} />
  </div>
);

export default ImagePLaceholder;
