import { useState } from "react";
import styles from "./styles";

const CompetitionMenuItem = ({ isActive, name, logo, theme }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  let containerStyle = styles.container;
  let themeStyle = styles[theme];

  if (isActive && isHovering) {
    containerStyle = themeStyle.activeAndHovering;
  }

  if (!isActive && isHovering) {
    containerStyle = themeStyle.notActiveAndHovering;
  }

  if (isActive && !isHovering) {
    containerStyle = themeStyle.activeAndNotHovering;
  }

  if (!isActive && !isHovering) {
    containerStyle = themeStyle.notActiveAndNotHovering;
  }

  return (
    <div
      style={containerStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={styles.content}>
        <div style={{ marginInline: 10 }}>
          <img style={styles.flag} src={logo} alt={`${name}-logo`} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={styles.competitionName}>{name}</span>
        </div>
      </div>
    </div>
  );
};

export default CompetitionMenuItem;
