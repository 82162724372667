import useMediaScreen from "@utils/hooks/useMediaScreen";
import CompetitionMenu from "./CompetitionMenu";

const SearchPage = () => {
  const { isSmall } = useMediaScreen();
  return (
    <div style={{ width: "100%", padding: isSmall ? 10 : 20 }}>
      <div
        style={{
          width: "100%",
          maxWidth: "1000px",
          marginTop: 10,
          margin: "auto",
        }}
      >
        <CompetitionMenu theme="dark" />
      </div>
    </div>
  );
};

export default SearchPage;
