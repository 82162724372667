import React from "react";
import { Table } from "antd";
import styles from "./styles";

import FixtureTeamsColumn from "./FixtureTeamsColumn";

const FixtureColumns = [FixtureTeamsColumn];

const FixturesTable = ({ title, fixtures, fixtureKey }) => {
  return (
    <div>
      {React.isValidElement(title) ? (
        title
      ) : (
        <div style={styles.container}>
          <span style={styles.title}>{title}</span>
        </div>
      )}

      <Table
        rowClassName={"table-striped-rows"}
        showHeader={false}
        rowKey={fixtureKey}
        dataSource={fixtures}
        columns={FixtureColumns}
        size="small"
        pagination={false}
      />
    </div>
  );
};

export default FixturesTable;
