const stylesPerSize = {
  small: {
    container: {
      overflow: "hidden",
      width: "100%",
      paddingBottom: 10,
    },
    subContainer: {
      overflow: "hidden",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    coverImage: {
      width: "100%",
      height: "min(400px, 32vh)",
      objectFit: "cover",
      objectPosition: "top left",
    },
    aboutContainer: {
      display: "block",
      padding: "20px 25px 0px 25px",
    },
    aboutSubContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 2,
      // background: "red",
    },
    aboutTitle: {
      color: "#7b7b7a",
      fontWeight: "500",
      fontSize: 15,
      letterSpacing: 0,
    },
    dot: {
      width: 7,
      height: 7,
      borderRadius: 3.5,
      background: "#7d0c82",
      marginRight: 5,
      marginBottom: 3,
    },
    title: {
      fontSize: "min(max(22.5px, 3vw), 24px)",
      fontFamily: "DINNextLTPro-Heavy",
      lineHeight: "19pt",
    },
    description: {
      color: "#7b7b7a",
      textAlign: "justify",
      lineHeight: "12pt",
    },
  },
  medium: {
    container: {
      overflow: "hidden",
      width: "100%",
      padding: 35,
      height: 400,
    },
    subContainer: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: "100%",
      border: "1px solid #d3d3d3",
      borderRadius: 5,
    },
    coverImage: {
      display: "block",
      width: "63%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "top left",
    },
    aboutContainer: {
      display: "block",
      width: "37%",
      padding: "0px 25px",
    },
    aboutSubContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px 0px",
    },
    aboutTitle: {
      color: "#7b7b7a",
      fontWeight: "500",
      fontSize: 15,
      letterSpacing: 0,
    },
    dot: {
      width: 7,
      height: 7,
      borderRadius: 3.5,
      background: "#7d0c82",
      marginRight: 5,
      marginBottom: 3,
    },
    title: {
      fontSize: "clamp(0.3rem, 2.5vw, 1.2rem)",
      fontFamily: "DINNextLTPro-Heavy",
      lineHeight: "18pt",
    },
    description: {
      color: "#1a1a1a",
      fontWeight: 400,
      lineHeight: "13pt",
    },
  },
  large: {
    container: {
      overflow: "hidden",
      width: "100%",
      height: 350,
      maxHeight: 350,
      marginTop: 40,
      marginBottom: 30,
      paddingInline: 30,
      borderRadius: 5,
    },
    subContainer: {
      overflow: "hidden",
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: "100%",
      borderRadius: 5,
      border: "1px solid #d3d3d3",
    },
    coverImage: {
      display: "block",
      width: "62%",
      height: "100%",
      position: "absolute",
      objectFit: "cover",
      objectPosition: "top left",
    },
    aboutContainer: {
      display: "block",
      width: "38%",
      padding: "0px 25px",
      position: "absolute",

      right: -1,
    },
    aboutSubContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px 0px",
    },
    aboutTitle: {
      color: "#7b7b7a",
      fontWeight: "500",
      fontSize: 15,
      letterSpacing: 0,
    },
    dot: {
      width: 7,
      height: 7,
      borderRadius: 3.5,
      background: "#7d0c82",
      marginRight: 5,
      marginBottom: 3,
    },
    title: {
      fontFamily: "DINNextLTPro-Heavy",
      fontSize: "clamp(1.5rem, 5vw, 1.5rem)",
      lineHeight: "22pt",
    },
    description: {
      color: "#1a1a1a",
      lineHeight: "16pt",
      fontSize: "16px",
    },
  },
};

export default stylesPerSize;
