const stylesPerSize = {
  small: {
    container: {
      overflow: "hidden",
      paddingInline: 0,
      paddingTop: 15,
      paddingBottom: 20,
      borderTop: "1px solid rgba(123,123,122, 0.2)",
    },
    subContainer: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    coverImage: {
      width: "100%",
      maxWidth: 120,
      maxHeight: 75,
      objectFit: "cover",
      objectPosition: "top left",
      borderRadius: 5,
    },
    aboutContainer: {
      width: "100%",
      paddingRight: 14,
    },
    aboutSubContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      lineHeight: "10pt",
    },
    aboutTitle: {
      color: "#7b7b7a",
      fontWeight: "400",
      fontSize: 14,
      letterSpacing: 0,
      padding: 0,
    },
    dot: {
      width: 7,
      height: 7,
      borderRadius: 3.5,
      background: "#7d0c82",
      marginRight: 5,
      marginBottom: 3,
    },
    title: {
      fontSize: "18px",
      fontFamily: "DINNextLTPro-Bold",
      lineHeight: "15pt",
      color: "#1a1a1a",
      letterSpacing: 0,
      paddingTop: 5,
    },
    description: {
      color: "#7b7b7a",
      textAlign: "justify",
      lineHeight: "12pt",
    },
  },
  medium: {
    container: {
      overflow: "hidden",
      paddingInline: 0,
      paddingTop: 16,
      paddingBottom: 5,
      borderTop: "1px solid rgba(123,123,122, 0.2)",
    },
    subContainer: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    coverImage: {
      width: "100%",
      maxWidth: 120,
      maxHeight: 75,
      objectFit: "cover",
      objectPosition: "top left",
      borderRadius: 5,
    },
    aboutContainer: {
      display: "block",
      width: "90%",
      paddingRight: 15,
    },
    aboutSubContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px 0px 5px 0px",
      lineHeight: "13pt",
    },
    aboutTitle: {
      color: "#7b7b7a",
      fontWeight: "400",
      fontSize: 15,
      padding: 0,
      fontFamily: "DINNextLTPro-Regular,sans-serif",
      lineHeight: "15pt",
    },
    dot: {
      width: 7,
      height: 7,
      borderRadius: 3.5,
      background: "#7d0c82",
      marginRight: 5,
      marginBottom: 3,
    },
    title: {
      fontSize: "min(max(19px, 3vw), 19px)",
      fontFamily: "DINNextLTPro-Bold, sans-serif",
      lineHeight: "17pt",
      fontWeight: 400,
      color: "#1a1a1a",
      paddingRight: 1,
    },
    description: {
      color: "#7b7b7a",
      textAlign: "justify",
      lineHeight: "12pt",
    },
  },
  large: {
    container: {
      overflow: "hidden",
      paddingInline: 0,
      paddingTop: 20,
      borderTop: "1px solid rgba(123,123,122, 0.2)",
    },
    subContainer: {
      display: "flex",
      flexDirection: "reverse",
    },
    coverImage: {
      width: 180,
      height: 120,
      objectFit: "cover",
      objectPosition: "top left",
      borderRadius: 5,
    },
    aboutContainer: {
      display: "block",
      width: "60%",
      paddingLeft: 20,
    },
    aboutSubContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px 0px 5px 0px",
      lineHeight: "13pt",
    },
    aboutTitle: {
      color: "#7b7b7a",
      fontWeight: "400",
      fontSize: 15,
      padding: 0,
      fontFamily: "DINNextLTPro-Regular,sans-serif",
      lineHeight: "15pt",
    },
    dot: {
      width: 7,
      height: 7,
      borderRadius: 3.5,
      background: "#7d0c82",
      marginRight: 5,
      marginBottom: 3,
    },
    title: {
      fontSize: "min(max(19px, 3vw), 19px)",
      fontFamily: "DINNextLTPro-Bold, sans-serif",
      lineHeight: "17pt",
      fontWeight: 400,
      color: "#1a1a1a",
      paddingRight: 1,
    },
    description: {
      color: "#7b7b7a",
      textAlign: "justify",
      lineHeight: "12pt",
    },
  },
};

export default stylesPerSize;
