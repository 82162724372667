import PropTypes from "prop-types";
import TeamLogo from "@components/core/modules/TeamLogo";

const FixtureTeamLogo = ({ logo }) => {
  return (
    <div style={{ marginInline: 5 }}>
      <TeamLogo name={logo} />
    </div>
  );
};

FixtureTeamLogo.propTypes = {
  /**
   * name of the logo to load
   */
  logo: PropTypes.string.isRequired,
};

export default FixtureTeamLogo;
