import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ImagePLaceholder from "../ImagePlaceholder";

const LazyImage = ({ src, logoSize, placeholderStyle, style }) => {
  const [loading, setLoading] = useState(true);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        minHeight: "100%",
      }}
    >
      {loading && (
        <div style={placeholderStyle}>
          <ImagePLaceholder logoSize={logoSize} />
        </div>
      )}
      <LazyLoadImage
        alt=""
        src={src}
        onLoad={() => setLoading(false)}
        style={style}
        threshold={100}
      />
    </div>
  );
};

export default LazyImage;
