import {
  TrophyOutlined,
  ReadOutlined,
  ReadFilled,
  TrophyFilled,
  FireOutlined,
  FireFilled,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import NavBarItem from "./NavBarItem";

import "./styles.css";

const menuItems = [
  {
    key: "news",
    matchingPath: "news",
    to: "news",
    icon: <ReadOutlined style={{ fontSize: 22, color: "#000" }} />,
    selectedIcon: (
      <ReadFilled
        style={{
          fontSize: 22,
          color: "#000",
        }}
      />
    ),
  },
  {
    key: "",
    matchingPath: "",
    to: "home",
    icon: <FireOutlined style={{ fontSize: 22, color: "#000" }} />,
    selectedIcon: (
      <FireFilled
        style={{
          fontSize: 22,
          color: "#000",
        }}
      />
    ),
  },
  {
    key: "competitions",
    matchingPath: "competitions",
    to: "competitions",
    icon: <TrophyOutlined style={{ fontSize: 22, color: "#000" }} />,
    selectedIcon: (
      <TrophyFilled
        style={{
          fontSize: 22,
          color: "#000",
        }}
      />
    ),
  },
];

function BottomNavBar() {
  const location = useLocation();
  let path = location.pathname.split("/")[2];

  return (
    <div className="bottom-nav">
      {menuItems.map((item) => (
        <NavBarItem {...item} path={path} />
      ))}
    </div>
  );
}

export default BottomNavBar;
