import { SearchOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router-dom";
import LocalizedLink from "@i18n/LocalizedLink";

import logo from "../ondebrief_logo.png";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

const LargeHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  let selectedKeyMenu = [location.pathname.split("/")[1]];

  return (
    <Header style={styles.header}>
      <div style={styles.content}>
        <LocalizedLink to="home">
          <img src={logo} alt="logo-ondebrief" style={styles.logo} />
        </LocalizedLink>

        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={selectedKeyMenu}
          style={styles.menu}
        >
          <Menu.Item key="news">
            <LocalizedLink to="news">
              <span style={styles.menuItem.title}>{t("header.news")}</span>
            </LocalizedLink>
          </Menu.Item>
        </Menu>
      </div>
    </Header>
  );
};

const styles = {
  header: {
    position: "sticky",
    top: 0,
    zIndex: 2,
    width: "100%",
    borderBottom: "1px solid #eff0f2",
    paddingInline: 0,
    paddingLeft: 40,
    boxShadow: "0px 1px 50px 1px rgba(0,0,0,0.009)",
    backgroundColor: "#fff",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "auto",
    width: "100%",
    maxWidth: "1200px",
  },
  menuIcon: {
    color: "#000",
    fontSize: "20px",
    fontWeight: "bold",
    backgroundColor: "#f5f5fa",
    padding: 10,
    borderRadius: 20,
  },
  logo: {
    height: "16px",
    fontSize: 40,
  },
  menu: {
    width: "90%",
    height: 64,
    paddingLeft: 20,
  },
  leftMenuItem: {
    marginLeft: "auto",
  },
  menuItem: {
    title: {
      fontSize: 20,
      fontWeight: "bold",
      letterSpacing: 1,
      color: "#000",
      marginLeft: "auto",
    },
  },
};

export default LargeHeader;
